import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`20:00 to establish a max of:`}</p>
    <p>{`1-Power Snatch`}</p>
    <p>{`1-Hang Squat Snatch`}</p>
    <p>{`2-OHS’s`}</p>
    <p><em parentName="p">{`*`}{`compare to 7/12/21`}</em></p>
    <p>{`then,`}</p>
    <p>{`10:00 amrap of:`}</p>
    <p>{`20-Alternating DB Hang Power Snatch (50/35)`}</p>
    <p>{`15-Pullups`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      